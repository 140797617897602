import React, { useContext, useState, useEffect, useRef } from 'react';
import { ScreenInfoContext } from '../../context/ScreenInfoContext';
import { OrdersContext } from '../../context/OrdersContext';
import { Card, CardBody, Progress } from 'reactstrap';
import './notification.css';
import '../../assets/css/progress.css'

import supabase from '../../lib/supabase';

import TodaysEvents from '../../components/TodaysEvents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

/*
###TODO: CHANGE THIS BEFORE RELEASING!
*/
var apiKey = 'AIzaSyBEs1NsW1D6jTf37IWo9vkBzs3vxntWQ38';
var calendarId = 'allison%40familyroomcoffee.com';
var maxEvents = 5;

const NotificationScreen = () => {
  const { activeLocation } = useContext(ScreenInfoContext);
  const { totalOrderCount, totalOrderLineItems } = useContext(OrdersContext);
  const [checklists, setChecklists] = useState([]);
  const [runningLow, setRunningLow] = useState([]);

  // Define the ref outside the useEffect
  const previousOrderCount = useRef();

  useEffect(() => {
    // Initialize the ref with the current count on the first render
    if (previousOrderCount.current == null) {
      previousOrderCount.current = totalOrderCount;
    }
  
    // Check if the current totalOrderCount is less than the previous one
    if (totalOrderCount < previousOrderCount.current) {
      getRunningLow();
    }
  
    // Update the ref to the current value for the next render
    previousOrderCount.current = totalOrderCount;
  }, [totalOrderCount]);

  useEffect(() => {
    if (!activeLocation) return;
    //Need an interval here to keep this up to date
    fetchChecklistStatus();
    getRunningLow();

    // Set up the interval
    const intervalId = setInterval(() => {
      fetchChecklistStatus();
    }, 300000); // 300000 milliseconds = 5 minutes
  
    // Clear interval on component unmount or activeLocation changes
    return () => clearInterval(intervalId);
  }, [activeLocation]);

  const getRunningLow = async () => {
    const { data, error } = await supabase
      .from('low_prep_items')
      .select('*')
      .eq('location_id', activeLocation.id);

      if (error) {
        throw error;
      }

      // Sort data based on the ratio of actual to par, in ascending order
      const sortedData = data.sort((a, b) => {
        // Compute the ratio for each item
        const ratioA = a.actual / a.par;
        const ratioB = b.actual / b.par;

        // Compare the ratios for sorting
        return ratioA - ratioB;
      });

      setRunningLow(sortedData);
  }

  const fetchChecklistStatus = async () => {
    try {
      const today = new Date();
      
      const { data, error } = await supabase
        .from('checklists')
        .select(
          `
            *,
            checklist_items (
              id,
              checklist_initials (initials, completion_date)
            )
          `
        )
        .eq('location_id',activeLocation.id)
        .eq('checklist_items.checklist_initials.completion_date',Intl.DateTimeFormat('en-US').format(today));

      if (error) throw error;
  
      const currentHour = today.getHours();
      const currentDay = today.getDay();
      const todayString = today.toDateString();
  
      const processedChecklists = data
        .map((checklist) => {
          // Check if the checklist should be displayed based on start hour or day of week
          //console.log(checklist.start_hour, checklist.day_of_week);
          let display = (checklist.start_hour && checklist.start_hour * 1 < currentHour + 1) || (checklist.day_of_week && checklist.day_of_week === currentDay);
  
          // Calculate completed items for today
          let completedToday = 0;
          const totalItems = checklist.checklist_items.length;
  
          checklist.checklist_items.forEach((item) => {
            if (Array.isArray(item?.checklist_initials) && item.checklist_initials.length > 0) completedToday++;
          });
  
          const completionPercent = totalItems
            ? (completedToday / totalItems) * 100
            : 0;

          //console.log(completionPercent,completedToday,totalItems);
  
          // Only return the checklist if it should be displayed and not fully completed
          if (display && completedToday < totalItems) {
            //console.log("returning", checklist);
            return {
              id: checklist.id,
              name: checklist.name,
              completedToday,
              totalItems,
              completionPercent,
            };
          } else {
            return null; // Exclude non-displayed or fully completed checklists
          }
        })
        .filter((checklist) => checklist !== null); // Filter out null values

      setChecklists(processedChecklists);
    } catch (error) {
      console.error('Error fetching checklist data:', error);
    }
  };

  if (!activeLocation) return (<div>Loading...</div>);

  return (
    <div id="notification-screen" className="dashboard-container">
      <Card className="quarter-card card" key="orders">
        <CardBody className='card_body'>
          <h3>Orders in Queue</h3>
          <div className="count">{totalOrderCount}</div>
        </CardBody>
      </Card>

      <Card className="quarter-card card" key="items">
        <CardBody className='card_body'>
          <h3>Items in Queue</h3>
          <div className="count">{totalOrderLineItems}</div>
        </CardBody>
      </Card>

      <Card className="half-card card" key="calendar">
        <CardBody className='card_body'>
          <h3>Calendar</h3>
          <div id="calendar">
            <TodaysEvents calendarId={calendarId} apiKey={apiKey} maxEvents={maxEvents} />
          </div>
        </CardBody>
      </Card>

      <Card className="half-card card" key="checklists">
        <CardBody className='card_body'>
          <h3>Checklist Progress</h3>
          <div id="checklist_progress" key="checklist_progress">
            {checklists.length > 0 ? (
              <div className="checklists" key="checklists">
                {checklists.map((checklist) => (
                  <div key={checklist.name}>
                    <div className="title" key="name">{checklist.name}</div>
                    <div className="fraction" key="fraction">{checklist.completedToday}/{checklist.totalItems}</div>
                    <div className="checklist-row" key="row">
                      <div className='progress-section'>
                        <Progress className={`progress-bar-rounded ${
                          checklist.completionPercent < 25
                            ? 'bar-red'
                            : checklist.completionPercent < 75
                            ? 'bar-yellow'
                            : 'bar-blue'
                        }`} style={{width:'95%'}} value={checklist.completionPercent}/>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>All Checklists Are Complete!</div>
            )}
          </div>
        </CardBody>
      </Card>

      <Card className="half-card card" key="running-low">
        <CardBody className='card_body'>
          <h3>Running Low</h3>
          <div id="running-low">
            {
              runningLow.map((item) => {
                console.log(item.name,item.actual, item.par, (item.actual / item.par), item)
                if ((item.actual / item.par) > 0.5) return null;

                const fontClass = (item.actual / item.par) < 0.25
                  ? 'text-red'
                  : (item.actual / item.par) < 0.5
                    ? 'text-yellow'
                    : 'text-blue';

                return (
                  <div key={item.name} className={`running-low-item progress-bar-rounded ${fontClass}`}>
                    <FontAwesomeIcon icon={faCircleExclamation} />&nbsp;
                    {item.name} {`(${(item.actual * 1).toFixed(1) * 1} remaining - count to verify)`}
                  </div>
                );
              })
            }
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default NotificationScreen;
